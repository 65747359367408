import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Layout } from "../components/_layout"

import { ICardItem } from "../components/_common/Card/Card"
import { NewsSection } from "../components/homepage"

const getNews = graphql`
    query {
        newsData: allContentfulLigasDeInteresArticulo(
            sort: { fields: [orden], order: ASC }
        ) {
            edges {
                node {
                    id
                    titulo
                    subtitulo
                    url
                    imagen {
                        fluid {
                            ...GatsbyContentfulFluid
                        }
                    }
                }
            }
        }
    }
`

const NoticiasYEventos: React.FC = () => {
  const { newsData } = useStaticQuery(getNews)

  const news: ICardItem[] = newsData.edges.map(({ node }: any) => ({
    id: node.id,
    titulo: node.titulo,
    subtitulo: node.subtitulo,
    url: node.url,
    imagen: node.imagen.fluid,
  }))

  return (
    <Layout
      withNav
      seoTitle="Ligas de interés de inclusión financiera"
      seoDescription="Ligas de interés de inclusión financiera"
    >
      <NewsSection isCompleteList { ...{ news } } />
    </Layout>
  )
}

export default NoticiasYEventos
